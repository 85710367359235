<template lang="pug">
include /mixins
+b.comments-wrapper(
  v-if="commentsCounter || isAuth"
)
  +b.g-container--variant_default
    +b.SECTION.comments(id='comments')
      +e.H2.title
        +e.title-text {{ _('Комментарии') }}
        +e.comments-count(
          v-if="commentsCounter"
        ) {{` ( ${commentsCounter} )`}}
      //- +if('user.is_authenticated')
      +e.inner.--form(v-if="isAuth")
        +e.comment-form
          +e.comment-img
            +b.ds-avatar--size_md.--appearance_rounded
              +e.IMG.image(
                v-if="user.avatar"
                width="67",
                height="67"
                :src="user.avatar"
              )
              +e.label(
                v-else
              ) {{  user && user.username && user.username[0] || '' }}

            //- +b.ds-avatar.--bg_none.--appearance_rounded.--size_full(v-if="currentUserAvatar !== 'None'")
            //-   +e.IMG.image(:src='currentUserAvatar')
            //- +b.ds-avatar.--appearance_rounded.--size_full(v-else)
            //-   +e.label.--size_lg {{ user && user.username }}
          +b.SEND-COMMENT(
            :has-access="hasAccess"
            :is-coach="user && user.isCoach || false"
            :obj-id="objId"
            :obj-type="objType"
            @finish="getList(form)"
            @improve-subscription="improveSubscription"
          )
      template(v-for="comment in commentsList")
        +e.item.comments__inner(
          v-if="!comment.complainted || comment.complainted && +isAuth === +comment.user.id"
        )
          comment-detail(
            :has-access="hasAccess"
            :comment='comment'
            @send-complain="complainForComment"
            @send-answer="isAuth? answerForComment($event) : authModalOpener()"
            @remove-comment="confirmDeletion($event)"
            @improve-subscription="improveSubscription"
          )
      +b.ds-panel.--space_xl
        +e.element.--offset_top
          +b.g-row.--justify_start.--align_center
            +b.g-cell.g-cols--12.UI-LIMIT-PAGINATION(
              :pagination='pagination'
              @input='handlePaginate'
              theme='light'
            )
</template>

<script>
import {
  getCommentList,
  getForumCommentList,
  removingComment,
} from '@api/comment.service.js';
import {
  usedDataSidebar,
} from '@api/cabinet.service.js';
import { clone } from 'ramda';
import { modalOpener } from '@utils/helpers'
import { getCookie } from '@utils/cookies'

const BuySubscription = () => import('../Modals/Interaction-modals/BuySubscription');
const confirmModal = () => import('../Modals/Interaction-modals/ConfirmModal');
const CommentAnswer = () => import('../Modals/Comments/CommentAnswer');
const CommentsComplain = () => import('../Modals/Comments/CommentsComplain.vue');
const LogIn = () => import('../Modals/Auth/Login');

export default {
  name: 'CommentsTemplate',
  props: {
    objId: [String, Number],
    objType: String,
    hasAccess: {
      type: Boolean,
      default: true,
    },
    requiredSubscription: String,
  },
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
      user: {},
      buyModalConfig: {
        props: {
          isComponent: BuySubscription,
          title: this._('Для продолжения, улучшите свой абонемент'),
          subscriptionType: this.requiredSubscription,
          variables: {
            theme: 'dark',
            isAuth: false,
          },
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: 593,
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        // actor: this.openAuthModal,
      },
      pagination: {},
      commentId: '',
      commentsCounter: '',
      form: {
        object_id: '',
        obj_content_type: '',
        limit: 10,
      },
      commentsList: '',
      isLiked: false,
      confirmModalConfig: {
        props: {
          isComponent: confirmModal,
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        actor: this.removeComment,
      },
    };
  },
  async mounted() {
    if (this.isAuth) await this.loadUserData()
    this.form.object_id = this.objId
    this.form.obj_content_type = this.objType
    this.getList(this.form)
  },
  methods: {
    modalOpener,
    loadUserData() {
      return usedDataSidebar.execute({}).then(res => {
        if (200 === res.code) {
          this.user = res.data.item
        }
      });
    },
    improveSubscription() {
      this.modalOpener(this.buyModalConfig)
    },
    authModalOpener() {
      this.modalOpener({
        props: {
          isComponent: LogIn,
        },
        // classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '345',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      })
    },
    confirmDeletion(data) {
      this.commentId = data
      this.modalOpener(this.confirmModalConfig)
    },
    removeComment() {
      console.log(getCookie('csrftoken'))
      return removingComment.execute({ id: this.commentId }).then(res => {
        console.log(res);
        this.getList()
      }).catch(err => {
        console.log(err);
      })
    },
    answerForComment(data) {
      this.modalOpener({
        props: {
          isComponent: CommentAnswer,
          objId: this.objId,
          objContentType: this.objType,
          rep: data,
          isCoach: this.user?.isCoach,
        },
        // classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        actor: this.emitHandler,
      })
    },
    complainForComment(data) {
      if (!this.isAuth) {
        this.authModalOpener()
      } else {
        this.modalOpener({
          props: {
            isComponent: CommentsComplain,
            id: data,
          },
          // classes: ['modal-wrapper--theme_dark'],
          config: {
            width: '430',
            height: 'auto',
            adaptive: true,
            scrollable: true,
          },
          actor: this.emitHandler,
        })
      }
    },
    emitHandler() {
      this.getList()
    },
    getList(params = this.form) {
      const transformedData = clone(params)
      const currentApi = 'forum.topic' === this.objType ? getForumCommentList : getCommentList
      return currentApi.execute(transformedData).then(res => {
        this.commentsList = clone(res.data.items.comments)
        this.pagination = res.data.pagination
        this.commentsCounter = res.data.items.commentsCount
        console.log(this.pagination);
      });
    },
    handlePaginate(params) {
      this.getList({ ...this.form, ...params })
    },
  },
};
</script>
