<template lang="pug">
include /mixins
//- +b.all-videos-wrapper
//-   +b.g-container--variant_default
//-     +b.SECTION.all-videos
//-       +e.H2.title.section-title: +trans Пакеты видео
//-       +e.filters-wrapper
//-         +b.D-CONTROL-MULTISELECT(
//-           label='title'
//-           track-by='title',
//-           type="select"
//-           :name='filterName',
//-           :value='checked.slug'
//-           v-model='checked',
//-           :options='filterItems'
//-           :placeholder='filterPlaceholder',
//-         )
//-       +e.list
//-         +for('video in video_list')
//-           +e.item
//-             +e.item-left
//-               +e.date
//-                 +e.calendar.icon-calendar
//-                 +e.date-num: +exp video.publication_date|date('d.m.Y')
//-               +e.item-title: +exp video.title
//-               +e.time
//-                 +e.time-icon.icon-time
//-                 +e.time-count!='('+exp('video.duration')+' '+trans('мин')+')'
//-             +e.item-right
//-               +e.hero
//-                 +e.hero-img
//-                   +b.ds-avatar.--appearance_rounded.--size_full
//-                     +e.image(src=exp('video.coach.avatar.training_size'))
//-                 +e.hero-name!=trans('тренер')+' '+exp('video.coach.first_name')+' '+exp('video.coach.last_name')
//-               //- How to add like?
//-               +e.like-button.icon-like-img
//-               +e.UL.categories-list
//-                 +e.LI.categories-item: +exp video.game_type.title
//-                 +e.LI.categories-item: +exp video.video_type.title
//-                 +e.LI.categories-item: +exp video.get_access_subscription_type_display()
//-       +if('is_paginated')
//-         +b.pagination.--dark
//-           +if('page_obj.has_previous()')
//-             +e.A.arrow.icon-arrow-left(href=exp('url_replace_GET(request, page_obj.previous_page_number())'))
//-             +else
//-             +e.arrow.icon-arrow-left
//-           +e.list
//-             +tag set numbered = numerate_pagination(page_obj.number, page_obj.paginator.num_pages, static=False, start_end=True)
//-             +for('num in numbered')
//-               +if('num==page_obj.number')
//-                 +e.A.item.--active: +exp num
//-                 +elif num == None
//-                 +e.SPAN.item ...
//-                 +else
//-                 +e.A.item(href=exp("url_replace_GET(request, num)")): +exp num
//-           +if('page_obj.has_next()')
//-             +e.A.arrow.icon-arrow-right(href=exp('url_replace_GET(request, page_obj.next_page_number())'))
//-             +else
//-             +e.arrow.icon-arrow-right
</template>

<script>
import {
  usersListUrl,
  // getVideoList,
} from '@api/base.service.js';
import { pathOr } from 'ramda';
// import { friendlyUrlParser } from '@resource/parser';
import { urlGetter } from '@utils/utils';

export default {
  props: ['filterAttributes'],
  data() {
    return {
      checked: '',
      options: [
        {
          slug: 'slug1',
          title: 'title1',
        },
        {
          slug: 'slug2',
          title: 'title2',
        },
        {
          slug: 'slug3',
          title: 'title3',
        },
      ],
      friendlyUrl: {
        filters: {},
      },
      userListResult: '',
    };
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      const data = JSON.parse(JSON.stringify(this.form));
      data.language = pathOr('', ['language', 'value'], this.form);
      data.languages = pathOr('', ['languages', 'value'], this.form);
      data.translation_type = pathOr('', ['translation_type', 'value'], this.form);
      data.work_type = pathOr('', ['work_type', 'value'], this.form);
      Object.keys(data).forEach(key => {
        if (!data[key]) delete data[key];
      });
      const requestFata = { filters: data };
      this.updateUrl(requestFata);
      return usersListUrl.execute(requestFata).then(result => {
        this.userListResult = result.data;
      });
    },
    getTitle({ props }) {
      return props.title
    },
    close() {
      this.$emit('close')
    },
    updateUrl(parameters) {
      const query = { filters: parameters };
      const zero = 0;
      this.friendlyUrl.filters = query.filters;
      const keys = Object.keys(this.friendlyUrl.filters);
      for (let x = 0; x <= keys.length; x++) {
        if (this.friendlyUrl.filters[keys[x]] !== undefined) {
          if (zero === this.friendlyUrl.filters[keys[x]].length) {
            delete this.friendlyUrl.filters[keys[x]];
          }
        }
      }
      const order = {
        path: ['prefix', 'filters', 'page'],
      };
      console.log(this.friendlyUrl.filters);
      // this.form = this.friendlyUrl.filters;
      // const shift = 1;
      // const currentPage = Math.floor(parameters.offset / parameters.limit) + shift;
      // const page = currentPage > shift ? currentPage : null;
      const page = null;
      const base = Object.keys(parameters.filters).length ? `${this.baseUrl}filters` : this.baseUrl;
      const fullUrl = urlGetter([base], order, { ...parameters, page });
      window.history.pushState({ url: fullUrl }, '', `${fullUrl}${window.location.search}`);
    },
    // updateValidator(errors) {
    //   const parsed = defaultValidatorErrorsParser(errors);
    //   console.log(parsed);
    //   this.nonFieldErrors = parsed.nonFieldErrors;
    //   this.$refs.validator.setErrors(parsed);
    // },
  },
};
</script>
