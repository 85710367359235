<template lang="pug">
include /mixins
+b.buy-subscription-wrapper
  +b.g-row.--space_none.--justify_center
    +b.g-cell.g-cols--narrow
      +b.BUTTON.complain-form__submit.btn.btn-red(
        @click.prevent="updateSubscription()"
      ) {{ _("Перейти к покупке абонемента") }}

</template>

<script>
// import {
//   getComplaintReasons,
//   createComplaint,
// } from '@api/complaint.service.js';
import { modalOpener } from '@utils/helpers'
import {
  prefixLanguage,
} from '@utils/urls';

const Login = () => import('../Auth/Login')
export default {
  name: 'BuySubscription',
  props: {
    subscriptionType: String,
  },
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
      authModal: {
        props: {
          isComponent: Login,
          userType: '',
          id: '',
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
    };
  },
  mounted() {
    console.log(this.$attrs);
  },
  methods: {
    modalOpener,
    updateSubscription() {
      if (!this.isAuth) {
        this.modalOpener(this.authModal)
        this.close()
      } else {
        window.location.href = prefixLanguage(this.subscriptionType ? `/subscription/?subscription=${this.subscriptionType}` : '/subscription/')
      }
    },
    close() {
      this.$emit('close')
    },
  },
};
</script>
