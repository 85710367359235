<template lang="pug">
<!-- eslint-disable max-len -->
include /mixins
+b.g-container--variant_default
  +b.SECTION.all-videos
    +e.filters-wrapper
      +b.ds-panel.--space_2xl
        +e.element.--offset_bottom
          +b.g-row.--appearance_spaced.--space_xl.--justify_center.--justify_between-lg
            template(v-for="filter in filterAttributes" )
              +b.g-cell.g-cols
                +b.D-CONTROL-MULTISELECT.control-descriptor--full_width(
                    @input="getList(form)"
                    :key="filter.name"
                    label='title'
                    track-by='title',
                    type="select"
                    :searchable="false"
                    :name='filter.name',
                    :value='filter.title'
                    v-model='form[filter.name]',
                    :options='filter.items'
                    :placeholder='filter.placeholder',
                    v-bem:d-control-multiselect="{'variant': 'dark', 'width': 'md'}"
                  )
    +b.articles__list.g-row.--justify-between
      template(v-for="(article, index) in articlesList.items" )
        +e.item.g-cell.g-cols--12
          +b.g-row.--justify-between.--full-width.--align_stretch
            +b.g-cell.g-cols--12.--narrow-sm
              +b-context('articles')
                +e.date
                  +e.date-num {{article.publicationDate.day}}
                  +e.date-month {{article.publicationDate.monthYear}}
                +e.full-date
                  +b.ds-panel.--space_md
                    +e.element.--offset_bottom
                      +b.g-row.--space_none.--align_center
                        +b.g-cols.g-cell--narrow
                          +b-context('articles')
                            +e.full-date-icon.icon-calendar
                        +b.g-cols.g-cell--narrow
                          +b-context('articles')
                            +e.full-date-num {{article.publicationDate.day}} {{article.publicationDate.monthYear}}
            +b.g-cell.g-cols--auto
              +b.g-row.--justify-between.--space_2xl
                +b.g-cell.g-cols--12
                  +b-context('articles')
                    +e.text
                      +e.TAG.text-title(
                        :tag="article.haveAccess ? 'a' : 'div'"
                        :href="article.haveAccess ? article.url : null"
                      ) {{article.title}}
                      +e.P.text-sub {{textCutter(article.description)}}
                +b.g-cell.g-cols--12
                  +b.g-row.--justify_center.--justify_end-sm.--justify_between-lg.--align_center.--appearance_spaced.--space_xl
                    +b.g-cell.g-cols--12.--auto-lg
                      +b.g-row.--appearance_spaced
                        +b.g-cell.g-cols
                          +b-context('articles')
                            +e.A.tag {{'#'}}{{article.subscriptionType.title}}
                        +b.g-cell.g-cols
                          +b-context('articles')
                            +e.A.tag {{'#'}}{{article.tableType.title}}
                    +b.g-cell.g-cols--narrow
                      +b-context('articles')
                        +e.A.button.btn.btn-red-trans(
                          v-if="article.haveAccess"
                          :href='article.url'
                          ) {{_('Читать статью')}}
                        template(v-else-if="!article.haveAccess")
                          template(v-if="isAuthenticated")
                            purchase-item(
                              :initial="{ content_type: 'article', id: article.id }"
                              #default="{ purchase, loading, non_field_errors }"
                            ): template(
                              v-tooltip="tooltipConfig"
                            )
                              +b.BUTTON.articles__button.btn.btn-red(
                                type="button"
                                @click="purchase"
                                :disabled="loading || non_field_errors && non_field_errors.length"
                              ) {{_('Купить')}} {{article.price + '$'}}
                          template(v-else)
                            +e.BUTTON.button.btn.btn-red(
                              @click.prevent="openAuthModal()"
                            ) {{_('Купить')}}  {{article.price + '$'}}
        //- +e.item(:key="video.id")
        //-   +e.item-left
        //-     +e.date
        //-       +e.calendar.icon-calendar
        //-       +e.date-num {{video.publicationDate}}
        //-     +e.A.item-title(:href='video.url') {{video.title}}
        //-     +e.time
        //-       +e.time-icon.icon-time
        //-       +e.SPAN.time-count {{'('}}{{video.duration + ' мин'}}{{')'}}
        //-   +e.item-right
        //-     +e.hero
        //-       +e.hero-img
        //-         +b.ds-avatar.--appearance_rounded.--size_full
        //-           +e.image
        //-             +b.ds-avatar.--appearance_rounded.--size_sm
        //-               +e.IMG.image(:src='video.coach.avatar')
        //-       +e.hero-name {{_('тренер')}} {{video.coach.firstName}} {{video.coach.lastName}}
        //-     //- How to add like?
        //-     +e.like-button.icon-like-img(
        //-       :class="isAuth === 'True'? video.isLiked? 'all-videos__like-button--is-active' : '' : 'not-active'"
        //-       @click="setLike(video.id, video.contentType, index)"
        //-     )
        //-     +e.UL.categories-list
        //-       +e.LI.categories-item {{video.gameType.title}}
        //-       +e.LI.categories-item {{video.videoType.title}}
        //-       +e.LI.categories-item {{video.subscriptionType.title}}
    +b.g-row.--justify_start.--align_center
      +b.g-cell.g-cols--12.UI-LIMIT-PAGINATION(
        :pagination='pagination'
        @input='handlePaginate'
      )
</template>

<script>
import {
  getArticles,
} from '@api/articles.service.js';
import { modalOpener } from '@utils/helpers'
import { clone } from 'ramda';

const loginForm = () => import('../Modals/Auth/Login');

export default {
  name: 'ArticlesController',
  props: ['filterAttributes', 'isAuth', 'coach'],
  data() {
    return {
      tooltipConfig: {
        content: 'non_field_errors && non_field_errors[0] || null',
        open: 'non_field_errors && non_field_errors[0] || false',
        delay: '200',
      },
      pagination: {},
      form: {
        access_type: '',
        game_type: '',
        subscription_type: '',
        coach: '',
        table_type: '',
        limit: '',
      },
      articlesList: '',
      isAuthenticated: '',
    };
  },
  methods: {
    modalOpener,
    openAuthModal() {
      this.modalOpener({
        props: {
          isComponent: loginForm,
          // path: res.data.item.redirect
        },
        config: {
          width: 345,
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      })
    },
    textCutter(text) {
      let sliced = text.slice(0, 520)
      if (sliced.length < text.length) {
        sliced += '...';
      }
      return sliced
    },
    getList(params) {
      const transformedData = clone(params)
      transformedData.limit = 6
      if (params) {
        transformedData.access_type = params?.access_type?.slug
        transformedData.game_type = params?.game_type?.slug
        transformedData.subscription_type = params?.subscription_type?.slug
        transformedData.coach = params?.coach?.slug
        transformedData.table_type = params?.table_type?.slug
      }
      return getArticles.execute(transformedData).then(res => {
        this.articlesList = res.data;
        this.pagination = res.data.pagination
      });
    },
    handlePaginate(params) {
      this.getList({ ...this.form, ...params })
    },
  },
  mounted() {
    this.isAuthenticated = window.IS_AUTHENTICATED
    console.log(this.filterAttributes);
    this.getList(this.form)
  },
};
</script>
