<template lang="pug">
include /mixins
file-upload(
  ref="upload",
  :autoload="true",
  @success="fileUploaded",
  @process="process",
  :handler="feed.sendFile"
  v-bind="$attrs"
)
  template(slot="cover")
    slot(name="dropzone-cover")

  template(slot="default", slot-scope="fileUpload")
    component(
      ref="room",
      :is="wrappedComponent",
      v-bind="$attrs",
      v-on="$listeners",
      :slotAreas="slotAreasMerged",
      :chat="chat",
      :userId="userId"
    )
    
      template(slot="file-uploader-clicker", slot-scope="clicker")
        slot(name="file-uploader-clicker", :open='fileUpload.open')
          button.stacked-elements__element.control-button(
            @click.prevent="fileUpload.open"
          ) file upload icon
            //- icon(:name="'attach_file'", :size="'lg'")

      template(slot="file-uploader-label", v-if="!windowOpened && fileProgress > 0", slot-scope="label")
        .wds-chat-room__label(@click="openWindow")
          button.control-button.control-button--style_default.control-button--size_xs
            icon(:name="'cloud_upload'", :size="'lg'")
            span.tag.tag--type_error.tag--round.tag--super(v-text="fileProgress")

      //- template(slot="file-uploader-window", slot-scope="window")
        wds-dialog-window(
          ref="window",
          dismissible="",
          :opened="window.opened",
          @input="window.handler",
          @open="windowOpened = true",
          @close="windowOpened = false"
        )
          slot(name="files-preview", :fileUpload="fileUpload")

      //- slot(:name="slot", :slot="slot", v-for="slot in namedSlots()")
      template(
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      )
        slot(
          :name="slot"
          v-bind="scope"
        )

</template>

<script>

/* eslint-disable */

import FileUpload from "./../../FileUpload/components/Handler/Handler.vue"

import MessagingInjectorMixin from '../../../utils/mixins/MessagingInjectorMixin';
import { SlotAreasMergerMixin } from '../../../utils/slot-areas';

const currentSlotAreas = {
  clickers: ['file-uploader-clicker'],
  dialogLabels: ['file-uploader-label'],
  dialogWindows: ['file-uploader-window'],
};

export default {
  components: {
    FileUpload,
  },

  mixins: [SlotAreasMergerMixin, MessagingInjectorMixin],

  currentSlotAreas,

  props: ['chat', 'userId', 'wrappedComponent', 'slots'],

  data() {
    return {
      fileProgress: 0,
      windowOpened: false,
    };
  },

  methods: {
    process(event) {
      if (!this.$refs.window) return;

      const count = event.files.length;

      if (0 < count && 0 === this.fileProgress) {
        this.openWindow();
      }

      if (0 === count && 0 !== this.fileProgress) {
        this.$refs.window.close();
      }

      this.fileProgress = count;
    },

    openWindow() {
      this.$refs.window.open();
    },

    fileUploaded(file) {
      this.feed.sendMessage({
        type: 'file',
        authorId: this.userId,
        chatId: this.chat.id,
        body: {
          name: file.name,
          size: file.info.result.size,
          source: file.info.result,
        },
      });

      this.$refs.upload.remove(file);
    },
  },
};

</script>
