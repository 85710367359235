// Auth
import Forum from '@app/Forum'
import SendForm from './Forms/SendForm';
import Login from './Modals/Auth/Login';
import Recover from './Modals/Auth/Recover';
import ChangePassword from './Modals/Auth/ChangePassword.vue';
import SetPassword from './Modals/Auth/SetPassword.vue';

// Comments

import CommentsComplain from './Modals/Comments/CommentsComplain';
import CommentsTemplate from './Comments/CommentsTemplate.vue';
import CommentDetail from './Comments/Detail'

// Search

import VideosSearch from './Search/VideosSearch';
import VideosFilter from './Search/VideosFilter';
import VideoController from './Search/VideoController.vue';
import ArticlesController from './Search/ArticlesController.vue';

// Courses
import ViewVideoToggle from './ProgressBar/ViewVideoToggle.vue';

// Progress

import CourseProgressBar from './ProgressBar/CourseProgressBar';

// Time

import TimeSelect from './Profile/TimeSelect';

// Language

import ToggleLanguage from './Language/ToggleLanguage';

// Slider

import UiSlider from './Slider/UiSlider';

// Forms

import MoreForm from './Forms/MoreForm';
import CreateTopic from './Forms/CreateTopic.vue';
import SendComment from './Forms/SendComment';
import ProfileForm from './Forms/ProfileForm';
import ContactUs from './Forms/ContactUs';
import JoinUs from './Forms/JoinUs.vue'
import SetLikes from './Interaction/SetLikes';
import Subscribe from './Interaction/Subscribe.vue';
import GetShared from './Interaction/GetShared';
import CopyToClipBoard from './Interaction/CopyToClipBoard.vue';
import NewsShare from './Interaction/NewsShare.vue';
import ClickOutside from './Interaction/ClickOutside.vue';
import Register from './Modals/Auth/Register.vue'
import NotifyButtons from './Interaction/NotifyButtons.vue'
// forum
import DeleteForumTopic from './Interaction/DeleteForumTopic.vue';
// Interaction
import AcceptCookie from './Interaction/AcceptSiteCookies.vue'
// Buy
import Purchase from './Interaction/Purchase.vue';
import BuySubscription from './Modals/Interaction-modals/BuySubscription.vue';
import SubscriptionTemplate from './Modals/Buy/SubscriptionTemplate.vue';
import UpdateSubscription from './Interaction/UpdateSubscription.vue';
// Training
import TrainingSignUp from './Modals/Interaction-modals/TrainingSignUp.vue';
import TrainingJoinBtn from './Modals/Interaction-modals/TrainingJoinBtn.vue';

const DateFormat = () => import('./DateFormat')

export default function install(Vue) {
  Vue.component('SendForm', SendForm);
  Vue.component('CommentDetail', CommentDetail)
  Vue.component('Login', Login);
  Vue.component('Recover', Recover);
  Vue.component('VideosSearch', VideosSearch);
  Vue.component('VideosFilter', VideosFilter);
  Vue.component('ToggleLanguage', ToggleLanguage);
  Vue.component('UiSlider', UiSlider);
  Vue.component('MoreForm', MoreForm);
  Vue.component('CreateTopic', CreateTopic);
  Vue.component('SendComment', SendComment);
  Vue.component('CommentsComplain', CommentsComplain);
  Vue.component('CourseProgressBar', CourseProgressBar);
  Vue.component('ProfileForm', ProfileForm);
  Vue.component('TimeSelect', TimeSelect);
  Vue.component('ContactUs', ContactUs);
  Vue.component('JoinUs', JoinUs)
  Vue.component('ChangePassword', ChangePassword);
  Vue.component('SetPassword', SetPassword);
  Vue.component('VideoController', VideoController);
  Vue.component('ArticlesController', ArticlesController);
  Vue.component('SetLikes', SetLikes);
  Vue.component('Subscribe', Subscribe);
  Vue.component('GetShared', GetShared);
  Vue.component('NewsShare', NewsShare);
  Vue.component('ClickOutside', ClickOutside);
  Vue.component('PurchaseItem', Purchase)
  Vue.component('DeleteForumTopic', DeleteForumTopic)
  Vue.component('BuySubscription', BuySubscription);
  Vue.component('TrainingSignUp', TrainingSignUp);
  Vue.component('TrainingJoinBtn', TrainingJoinBtn);
  Vue.component('CopyToClipBoard', CopyToClipBoard);
  Vue.component('SubscriptionTemplate', SubscriptionTemplate);
  Vue.component('CommentsTemplate', CommentsTemplate);
  Vue.component('ViewVideoToggle', ViewVideoToggle);
  Vue.component('UpdateSubscription', UpdateSubscription);
  Vue.component('Register', Register);
  Vue.component('date-format', DateFormat)
  Vue.component('NotifyButtons', NotifyButtons)
  Vue.component('AcceptCookie', AcceptCookie)

  Vue.use(Forum)
}
