<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="auth-form"
      )
        +b.g-row.--justify_center
          +b.g-cell.g-cols--11
            +b.ds-panel.--space_xl
              +e.element.--offset_vertical(v-if="!isRegitered")
                +b.H2.auth-form__title.--offset_none {{_('Регистрация')}}
                +b.P.auth-form__text.--offset_none(
                  v-if="!codeIsSended"
                ) {{_('Для успешной регистрации на сайте необходимо обязательно указать Email')}}
                //- eslint-disable-next-line
                +b.P.auth-form__text.--offset_none(
                  v-if="codeIsSended"
                ) {{_('На ваш Email отправлено письмо с кодом подтверждения,')}}
                  | {{_(' введите код в поле (Код подтверждения), для успешной регистрации на сайте.')}}
            +e.element.--offset_vertical(v-if="isRegitered")
              +b.H2.auth-form__title.--offset_none {{_('Регистрация подтверждена!')}}
              +b.P.auth-form__descr.--offset_none {{_('Теперь вы можете авторизироваться используя ваши данные.')}}
        row(
          v-if="!isRegitered"
          appearance='spaced'
          )
          template(v-for='field in fields')
            cell(
              v-if="field.name !== 'code'? true : codeIsSended"
              :cols='field.cols'
              :key='field.name'
            )
              +b.TAG(
                :tag='field.tag || "d-control-input"'
                :input-label='field.label'
                :name='field.name'
                :placeholder='field.placeholder'
                :rules='field.rules'
                :ref='field.name'
                :type='field.type || "text"'
                :class='{"is-textarea": field.tag }'
                :prepend-icon='field.icon',
                v-model='form[field.name]'
                v-bind='[field.props]'
                v-bem:d-control-input="{'variant': 'light'}"
                :readonly="field.name === 'code'? false : codeIsSended",

              )
          +b.g-cell.g-cols--12(v-if="codeIsSended")
            +b.ds-panel.--space_none
              +e.element
                +b.P.auth-form__text {{_('Иногда письмо с кодом подтверждения попадает в "Спам". Убедитесь, что вы проверили все папки на почте.')}}
        +b.ds-panel.--space_lg(v-if="!codeIsSended")
          +e.element.--offset_top
        +b.BUTTON.auth-form__register.btn.btn-red(
          v-if="!codeIsSended"
          @click.prevent="prepareData(valid)"
          :class="!isAgree? 'btn--disabled' : ''"
          :disabled="!isAgree"
        ) {{_('Отправить проверочный код')}}
        +b.ds-panel.--space_lg(v-if="codeIsSended")
          +e.element.--offset_top
        row(justify="center")
          cell(cols="12")
            +b.BUTTON.auth-form__register.btn--full_width.btn-red(
              v-if="codeIsSended"
              type='submit'
              @click.prevent="prepareEmailData(valid)"
            ) {{_('Зарегистрироваться')}}
          cell(cols="narrow")
            +b.BUTTON.auth-form__login(
              @click.prevent="openAuthModal(), close()"
            ) {{_('Войти')}}
        +b.auth-form__inner(v-if="!codeIsSended")
          +b.auth-form__checkbox
            +b.INPUT.auth-form__checkbox-input(
              type='checkbox'
              @change="isAgree = !isAgree"
              )
          +b.P.auth-form__text {{_('Я соглашаюсь с')}}
            +b.A.auth-form__text-link(:href="privacyPoliceLink") {{_('политикой конфиденциальности')}}
            +b.SPAN.auth-form__inner-text {{_("и")}}
            +b.A.auth-form__text-link(:href="privacyPoliceLink") {{_('правилами сайта')}}
        button.vm--modal-close-button(
          v-if="!refId"
          type="button",
          :aria-label="_('Close')",
          @click="$emit('close')",
        ): span(aria-hidden="true") &times;
</template>

<script>
import {
  registerResource,
  registerConfirm,
} from '@api/auth.service.js';
import { modalOpener } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

const loginForm = () => import('./Login.vue');

export default {
  props: ['refId'],
  mixins: [FormMixin],
  data() {
    this.privacyPoliceLink = window.CONFIG.PRIVACY_POLICY
    this.rulesLink = window.CONFIG.RULES
    return {
      isRegitered: false,
      isAgree: false,
      codeIsSended: false,
      form: {
        username: '',
        password: '',
        referrer: this.refId,
      },
      nonFieldErrors: '',
      name: 'Login',
      regConfirmModalConfig: {
        props: {
          title: this._('Create invoice'),
          // isComponent: InvoiceCreateForm,
          // clientId: '',
          // clientName: '',
          // orderId: '',
          // orderType: 'Shipping',
        },
        config: {
          width: '700',
          height: 'auto',
          adaptive: false,
          scrollable: true,
        },
        actor: this.openAuthModal,
      },
      fields: [
        {
          name: 'username',
          rules: 'required',
          type: 'text',
          tag: 'd-control-input',
          placeholder: this._('Никнейм*'),
          cols: '12',
        },
        {
          name: 'email',
          rules: 'required',
          type: 'email',
          tag: 'd-control-input',
          placeholder: this._('Email*'),
          cols: '12',
        },
        {
          name: 'password',
          rules: 'required|min:8',
          type: 'password',
          tag: 'd-control-input',
          placeholder: this._('Пароль*'),
          cols: '12',
        },
        {
          name: 'code',
          rules: 'required',
          type: 'text',
          tag: 'd-control-input',
          placeholder: this._('Код подтверждения*'),
          cols: '12',
        },
      ],
    };
  },
  methods: {
    modalOpener,
    openAuthModal() {
      if (this.refId) {
        window.location.pathname = '/'
      } else {
        this.modalOpener({
          props: {
            isComponent: loginForm,
            // path: res.data.item.redirect
          },
          config: {
            width: 345,
            height: 'auto',
            adaptive: true,
            scrollable: true,
          },
        })
      }
    },
    async prepareEmailData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      const tranformedData = {
        email: this.form.email,
        code: this.form.code,
      }
      this.catchFormErrors(registerConfirm.execute({}, tranformedData).then(res => {
        if (202 === res.code) {
          this.modalOpener({
            props: {
              title: this._('Регистрация прошла успешно!'),
            },
            config: {
              width: 345,
              height: 'auto',
              adaptive: true,
              scrollable: true,
            },
            classes: ['modal-wrapper--theme_dark'],
            actor: this.openCabinet,
          })
          this.$emit('close')
        }
      }))
    },
    openCabinet() {
      window.location.pathname = '/cabinet/'
    },
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      console.log(this.form);
      return registerResource.execute({}, this.form).then(res => {
        if (201 === res.code) {
          this.codeIsSended = true
        }
      });
    },
    close() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      console.log(parsed);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
