<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="send-comment-form"
      )
        +b.g-row.--appearance_spaced.--space_md.--align_center.--justify_center.--justify_between-sm
          +b.g-cell.g-cols--12(v-if="nonFieldErrors")
            +b.errors-wrapper {{ nonFieldErrors[0] }}
          +b.g-cell.g-cols--12(
            v-for='field in fields'
            :key='field.name'
          ).relative
            +b.TAG(
              :id="`js-comment_${field.name}`"
              :tag='field.tag || "d-control-input"'
              :input-label='field.label'
              :name='field.name'
              :placeholder='field.placeholder'
              :rules='field.rules'
              :ref='field.name'
              :type='field.type || "text"'
              :class='{"is-textarea is-textarea--comment": field.tag }'
              :prepend-icon='field.icon',
              @input="checkScrollbar()"
              v-model='form.text'
              v-bind='[field.props]'
              v-bem:is-textarea="{'size': 'sm'}"
            )
          +b.g-cell.g-cols--12-xl
            +b.BUTTON.send-comment-submit.btn.btn-red(
              id="js-comment-btn"
              type='submit'
              @click.prevent="prepareData(valid)"
              ) {{ _('Отправить') }}
          +b.g-cell.g-cols--12-xl(
            v-if="isCoach",
          )
            +e.ROW.coach(space="xs")
              cell(cols="narrow")
                ds-inliner(size="2xl")
                  +b.INPUT.auth-form__checkbox-input.--border_light(
                      v-model.props="form.asCoach"
                      type='checkbox'
                    )
              cell(cols="narrow")
                ds-caption(color="defaul" class="auth-form__checkbox-input-text") {{_('Как тренер')}}
</template>

<script>
import {
  createComment,
} from '@api/comment.service.js';

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  name: 'SendComment',
  mixins: [FormMixin],
  props: {
    objId: [String, Number],
    objType: String,
    isCoach: Boolean,
    hasAccess: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        objectId: this.objId,
        objContentType: this.objType,
        text: '',
        asCoach: undefined,
      },
      nonFieldErrors: '',
      name: 'More',
      fields: [
        {
          name: 'textarea',
          type: 'textarea',
          tag: 'd-control-textarea',
          rules: 'max:1000',
          placeholder: this._('Оставить комментарий'),
        },
      ],
    };
  },
  mounted() {
    if (this.isCoach) {
      this.form.asCoach = true
    }
  },
  methods: {
    checkScrollbar() {
      const textArea = document.getElementById('js-comment_textarea')
      const commentBtn = document.getElementById('js-comment-btn')
      if (textArea.scrollHeight > textArea.clientHeight) {
        commentBtn.classList.add('has-scrollbar')
      } else {
        commentBtn.classList.remove('has-scrollbar')
      }
    },
    async prepareData(valid) {
      if (!this.hasAccess) {
        this.$emit('improve-subscription')
        return
      }
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      return createComment.execute({}, this.form).then(res => {
        if (201 === res.code) {
          this.form.text = ''
          this.$emit('finish', res.code)
        }
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
