<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="contact-form"
      )
        +b.H2.contact-form__title {{ _("Связаться с нами") }}
        row(
          appearance='spaced'
        )
          cell(
            v-for='field in fields'
            :cols='field.cols'
            :key='field.name'
          )
            +b.TAG(
              :tag='field.tag || "d-control-input"'
              :input-label='field.label'
              :name='field.name'
              :placeholder='field.placeholder'
              :rules='field.rules'
              :ref='field.name'
              :type='field.type || "text"'
              :class='{"is-textarea": field.tag }'
              :prepend-icon='field.icon',
              v-model='form[field.name]'
              v-bind='[field.props]'
              v-bem:d-control-input="{'variant': 'dark-second'}"
            )
        +b.BUTTON.contact-form__submit.btn.btn-red(type='submit' @click.prevent="prepareData(valid)") {{ _("Отправить") }}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {
        topic: '',
        message: '',
      },
      nonFieldErrors: '',
      name: 'Contact Us',
      fields: [
        {
          name: 'topic',
          type: 'text',
          tag: 'd-control-input',
          label: this._('Тема'),
        },
        {
          name: 'message',
          rules: 'required',
          type: 'text',
          tag: 'd-control-textarea',
          label: this._('Сообщение'),
        },
      ],
    };
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      // return loginResource.execute({}, this.form).then(result => {
      //   window.location.pathname = result.data.item.redirect;
      // });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      console.log(parsed);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
