<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="auth-form--label"
        v-if="!passIsReset"
      )
        +b.H2.auth-form__title {{_('Установите новый пароль')}}
        +b.g-row.--appearance_spaced.--space_2xl
          +b.g-cell.g-cols--12(
            v-for='field in fields'
            :key='field.name'
          )
            +b.TAG(
              :tag='field.tag || "d-control-input"'
              :input-label='field.label'
              :name='field.name'
              :placeholder='field.placeholder'
              :rules='field.rules'
              :ref='field.name'
              :type='field.type || "text"'
              :class='{"is-textarea": field.tag }'
              :prepend-icon='field.icon',
              v-model='form[field.name]'
              v-bind='[field.props]'
              v-bem:d-control-input="{'variant': 'light'}"
            )
        +b.BUTTON.auth-form__submit.btn.--offset-top_md.btn-red(type='submit' @click.prevent="prepareData(valid)") {{_('Сохранить')}}
</template>

<script>
import {
  passRecoverySet,
} from '@api/auth.service.js';
import { modalOpener } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  mixins: [FormMixin],
  props: {
    auth_link: {
      type: [String, Number],
      required: true,
      default: '',
    },
    uid: {
      type: [String, Number],
      required: true,
      default: '',
    },
    token: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      passIsReset: false,
      regNewPassConfig: {
        props: {
          title: this._('Новый пароль принят.'),
          subtitle: this._('Теперь вы можете войти испльзуя новый пароль.'),
          path: this.auth_link,
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      form: {
        newPassword1: '',
        newPassword2: '',
        uid: this.uid,
        token: this.token,
      },
      nonFieldErrors: '',
      name: 'Login',
      fields: [
        {
          name: 'newPassword1',
          rules: 'required',
          type: 'password',
          tag: 'd-control-input',
          placeholder: this._('Новый пароль'),
          label: this._('Пароль'),
          cols: '12',
        },
        {
          name: 'newPassword2',
          rules: 'required',
          type: 'password',
          tag: 'd-control-input',
          placeholder: this._('•••••••••••'),
          label: this._('Повторите пароль'),
          cols: '12',
        },
      ],
    };
  },
  methods: {
    modalOpener,
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      return passRecoverySet.execute({}, this.form).then(res => {
        if (202 === res.code) {
          this.passIsReset = true
          this.modalOpener(this.regNewPassConfig)
          this.$refs.validator.reset();
        }
      });
    },
    close() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      console.log(parsed);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
