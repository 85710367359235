<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="more-form"
      )
        +b.g-row.--justify_center.--appearance_spaced.--space_xl
          +b.g-cell.g-cols--narrow(
            v-for='field in fields'
            :cols='field.cols'
            :key='field.name'
          )
            +b.TAG(
              :tag='field.tag || "d-control-input"'
              :input-label='field.label'
              :vid='field.name'
              :placeholder='field.placeholder'
              :rules='field.rules'
              :ref='field.name'
              :type='field.type || "text"'
              :class='{"is-textarea": field.tag }'
              :prepend-icon='field.icon',
              v-model='form[field.name]'
              v-bind='[field.props]'
              v-bem:d-control-input="{'variant': 'dark-second'}"
            )
          +b.g-cell.g-cols--narrow
            +b.BUTTON.more-form__submit.btn.btn-red(type='submit' @click.prevent="prepareData(valid)") {{_("Подписаться")}}
</template>

<script>
import {
  getNewsSubscribe,
} from '@api/callback.service.js';
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';
import { modalOpener } from '@utils/helpers'

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {
        email: '',
      },
      nonFieldErrors: '',
      name: 'More',
      fields: [
        {
          name: 'email',
          rules: 'required',
          type: 'email',
          tag: 'd-control-input',
          placeholder: this._('Введите свой Email'),
        },
      ],
      newsSubscribeModal: {
        props: {
          title: this._('Подписка на новостную рассылку оформлена'),
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: 593,
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        // actor: this.openAuthModal,
      },
    };
  },
  methods: {
    modalOpener,
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      return getNewsSubscribe.execute({}, this.form).then(res => {
        if (201 === res.code) {
          this.modalOpener(this.newsSubscribeModal)
          this.form.email = ''
        }
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      console.log(parsed);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
