<template lang="pug">
include /mixins
+b.FORM.videos-search
  +e.INPUT.input(:placeholder='_("Введите название видео")')
  +e.BUTTON.button
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  mixins: [FormMixin],
  data() {
    return {
    };
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      // return loginResource.execute({}, this.form).then(result => {
      //   window.location.pathname = result.data.item.redirect;
      // });
    },
    close() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      console.log(parsed);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
