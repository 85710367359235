<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="auth-form"
      )
        +b.H2.auth-form__title {{_('Авторизация')}}
        row(
          appearance='spaced'
        )
          +b.g-cell.g-cols--12(v-if="nonFieldErrors")
            +b.errors-wrapper {{ nonFieldErrors[0] }}
          cell(
            v-for='field in fields'
            :cols='field.cols'
            :key='field.name'
          )
            +b.TAG(
              @input.once="nonFieldErrors = ''"
              :tag='field.tag || "d-control-input"'
              :input-label='field.label'
              :name='field.name'
              :placeholder='field.placeholder'
              :rules='field.rules'
              :ref='field.name'
              :type='field.type || "text"'
              :class='{"is-textarea": field.tag }'
              :prepend-icon='field.icon',
              v-model='form[field.name]'
              v-bind='[field.props]'
              v-bem:d-control-input="{'variant': 'light'}"
            )
        row(justify="end")
          cell(cols="narrow")
            modal-trigger(
              name='recover'
              url='UiElements/Modals/Auth/Recover'
            )
              template(#default='{ open }')
                +e.A.restore(
                  @click.prevent="open(), close()"
                ) {{_('Восстановить пароль')}}
        +b.BUTTON.auth-form__submit.btn.btn-red(type='submit' @click.prevent="prepareData(valid)") {{_('Войти')}}
        row(justify="center")
          cell(cols="narrow")
            modal-trigger(
              name='Register'
              url='UiElements/Modals/Auth/Register'
            )
              template(#default='{ open }')
                +e.BUTTON.register(
                  @click.prevent="open(), close()"
                ) {{_('Зарегистрироваться')}}
        +b.P.auth-form__text {{_('Авторизуясь на сайте Вы соглашаетесь с')}}
          +b.A.auth-form__text-link(:href="privacyPoliceLink") {{_('политикой конфиденциальности')}}
          +b.SPAN.auth-form__inner-text {{_('и')}}
          +b.A.auth-form__text-link(:href="rulesLink") {{_('правилами сайта')}}
        button.vm--modal-close-button(
          type="button",
          :aria-label="_('Close')",
          @click="$emit('close')",
        ): span(aria-hidden="true") &times;

</template>
<script>
import {
  loginResource,
} from '@api/auth.service.js';

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  mixins: [FormMixin],
  props: {
    next: {
      type: String,
    },
  },
  data() {
    this.privacyPoliceLink = window.CONFIG.PRIVACY_POLICY
    this.rulesLink = window.CONFIG.RULES
    return {
      form: {
        username: '',
        password: '',
      },
      nonFieldErrors: '',
      name: 'Login',
      fields: [
        {
          name: 'login',
          rules: 'required',
          type: 'email',
          tag: 'd-control-input',
          placeholder: this._('Email* или Никнейм'),
          cols: '12',
        },
        {
          name: 'password',
          rules: 'required',
          type: 'password',
          tag: 'd-control-input',
          placeholder: this._('Пароль*'),
          cols: '12',
        },
      ],
    };
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      return loginResource.execute({}, this.form).then(res => {
        if (200 === res.code) {
          if (this.next) {
            window.location.reload();
          } else {
            window.location.pathname = res.data.item.redirect;
          }
        }
      });
    },
    close() {
      this.$emit('close')
    },
    openAnotherModal() {
      console.log(1)
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      console.log(parsed);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
