<template lang="pug">
  include /mixins
  slick(
    ref="slick"
    @init='emmiter'
    @reInit="handleReInit"
    :options="options"
    :class='"default-slider"',
  )
    slot(name='slide')
    template(#prevButton='')
      slot(name='prev')
        +b.controls--variant_circle.controls-left.mdi.mdi-chevron-left.SPAN
    template(#nextButton='')
      slot(name='next')
        +b.controls--variant_circle.controls-right.mdi.mdi-chevron-right.SPAN

</template>
<script>
export default {
  name: 'UiSlider',
  props: {
    options: {},
    reiniter: {},
  },
  watch: {
    reiniter() {
      this.reInit()
    },
  },
  mounted() {
    if (this.reiniter) {
      const time = 1250
      this.reInit()
      setTimeout(() => {
        this.reInit()
      }, time)
    }
  },
  methods: {
    emmiter(event, slick) {
      this.$emit('init', slick)
    },
    reInit() {
      console.log('reinit')
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$refs.slick.destroy()

      this.$nextTick(() => {
        this.$refs.slick.create()
      })
    },
    handleReInit(event, slick) {
      console.log(event, slick)
    },
  },
}
</script>
