<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="auth-form--recover"
      )
        +b.H2.auth-form__title(
          v-if="!emailSended"
        ) {{_('Восстановление пароля')}}
        +b.g-row.--justify_center(
           v-else
        )
          +b.g-cell.g-cols--11
            +b.ds-panel.--space_xl
              +e.element.--offset_vertical
                +b.P.auth-form__title.--offset_none
                  | {{_('На ваш Email отправлено письмо с инструкцией по смене пароля.')}}
        row(
          appearance='spaced'
          justify="end"
        )
          cell(
            v-if="!emailSended"
            v-for='field in fields'
            :cols='field.cols'
            :key='field.name'
          )
            +b.TAG(
              :tag='field.tag || "d-control-input"'
              :input-label='field.label'
              :name='field.name'
              :placeholder='field.placeholder'
              :rules='field.rules'
              :ref='field.name'
              :type='field.type || "text"'
              :class='{"is-textarea": field.tag }'
              :prepend-icon='field.icon',
              v-model='form[field.name]'
              v-bind='[field.props]'
              v-bem:d-control-input="{'variant': 'light'}"
            )
          cell(cols="12")
            +b.BUTTON.auth-form__submit.btn--full_width.btn-red(
              v-if="!emailSended"
              type='submit'
              @click.prevent="prepareData(valid)")
              | {{_('Восстановить пароль')}}
            +b.BUTTON.auth-form__submit.btn--full_width.btn-red(
              v-else
              type='submit'
              @click.prevent="close")
              | {{_('Закрыть')}}
        button.vm--modal-close-button(
          type="button",
          :aria-label="_('Close')",
          @click="$emit('close')",
        ): span(aria-hidden="true") &times;

</template>

<script>
import {
  passRecoveryResource,
} from '@api/auth.service.js';
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  mixins: [FormMixin],
  data() {
    return {
      emailSended: false,
      form: {
        email: '',
      },
      nonFieldErrors: '',
      name: 'Recover',
      fields: [
        {
          name: 'email',
          rules: 'required',
          type: 'email',
          tag: 'd-control-input',
          placeholder: this._('Email*'),
          cols: '12',
        },
      ],
    };
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    sendEmailHandler() {

    },
    send() {
      return passRecoveryResource.execute({}, this.form).then(res => {
        if (200 === res.code) {
          this.emailSended = true
        }
        // window.location.pathname = result.data.item.redirect;
      });
    },
    close() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      console.log(parsed);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
